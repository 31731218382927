.App {
  text-align: center;
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-top: 30px; /* Reduced top padding */
  background-color: #e6f0fa;
}

.amazing-action-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px; /* Reduced margin */
}

.alphabet-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.background-animation {
  position: absolute;
  z-index: -1;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px; /* Reduced gap */
  margin: 15px 0; /* Reduced margin */
}

.letter-button {
  border: 1px solid #ddd; /* Reduced border size */
  border-radius: 8px; /* Reduced border radius */
  padding: 4px; /* Reduced padding */
  background-color: #f0f8ff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
  transition: transform 0.2s, background-color 0.3s, border 0.3s;
}

.letter-button img {
  width: 80px; /* Scaled down image size */
  height: 80px;
}

.letter-button.selected {
  background-color: #3bffaa97;
  border: 1px solid #4caf50;
}

.letter-button:hover {
  transform: scale(1.03); /* Slightly reduced hover scaling */
  background-color: #b0d4f1;
}

.invisible-button {
  border: none;
  background-color: transparent;
  visibility: hidden;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px; /* Reduced gap */
  margin-top: 15px; /* Reduced top margin */
}

.controls.modern-controls {
  gap: 8px;
  padding: 8px 0; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.control-button {
  padding: 4px 8px; /* Reduced padding */
  border: none;
  background-color: transparent;
  font-size: 1.4em; /* Reduced font size */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.control-button:hover {
  background-color: #f0f0f0;
}

.play-pause {
  font-size: 1.8em; /* Reduced font size */
}

.progress-bar {
  width: 90%; /* Reduced width */
  margin: 0 8px;
}

.volume-control {
  display: flex;
  align-items: center;
  gap: 8px;
}

.volume-control input[type='range'] {
  width: 80px; /* Slightly reduced width */
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .buttons {
    grid-template-columns: repeat(3, 1fr); /* Adjust grid for smaller screens */
  }

  .letter-button img {
    width: 64px; /* Further reduce image size on smaller screens */
    height: 64px;
  }

  .play-pause {
    font-size: 1.5em;
  }

  .progress-bar {
    width: 80%;
  }

  .volume-control input[type='range'] {
    width: 60px; /* Further reduce volume control width */
  }
}
